import React from "react"
import Layout from "../components/layout"
import config from "../utils/siteConfig"
import Button from "../components/Atoms/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

export default function ThankYou({ data }) {
  debugger;
  const page = data.allContentfulZThankyou.edges[0].node

  const {
    id,
    metaData,
    tyHd,
    tyTxt1,
    tyTxt2,
    tyBtn
  } = page

  return (
    <Layout
      IsHeaderImageDark={false}
      navColour={"white"}>
      <head>
        <title>Wunderloop: Thank you</title>
        <meta charset="utf-8" />
        <meta name="robots" content="noindex" />
      </head>

      <section className="bg-white">
        <div className="thankyou-container">
          <div className="container bg-white">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <h2 className="align-top h2-huge h2-drakgray">
                    {tyHd}
                  </h2>
                  <div className="row justify-content-center">
                    <h2 className="text-center align-top h1-medium2LightS text-blue2 pt-3">
                      {tyTxt1.tyTxt1}
                    </h2>
                  </div>
                  {/* <div className="text-center pt-4">
                    <Button
                      className='btns'
                      buttonStyle='btn--primaryPurple2'
                      buttonSize='btn--txt2'
                      href={config.navigationURL_home}
                    >
                      {tyTxt2.tyTxt2}
                      <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                    </Button>
  </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}


export const query = graphql`
  query($locale: String!) {
    allContentfulZThankyou (
      filter: {
        node_locale: {eq: $locale}
      }) {
      edges {
        node {
          id
          metaData {
            slug
            title
          }
          tyHd
          tyTxt1 {
            tyTxt1
          }
          tyTxt2 {
            tyTxt2
          }
          tyBtn
        }
      }
    }
  }
`
